import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type ISettingsParams = {
  showCoverPhoto: SettingsParamType.Boolean;
  showFollowButton: SettingsParamType.Boolean;
  followButtonText: SettingsParamType.String;
  messageButtonText: SettingsParamType.String;
  followingCountersText: SettingsParamType.String;
  followersCountersText: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  showCoverPhoto: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showFollowButton: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  followButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('profile-widget.follow'),
  },
  messageButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('profile-widget.message'),
  },
  followingCountersText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('profile-widget.following'),
  },
  followersCountersText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('profile-widget.followers'),
  },
});
